import '@tamagui/core/reset.css';
import '@tamagui/font-fira-mono/css/400.css';
import '@tamagui/font-fira-mono/css/800.css';
import 'raf/polyfill';

import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme';
import Head from 'next/head';
import React from 'react';
import type { SolitoAppProps } from 'solito';
import type { AppProps } from 'next/app';

import { NextSeo, OrganizationJsonLd } from 'next-seo';
import Wrapper from 'vellapps/components/Wrapper';
import DynamicFavicon from 'vellapps/components/DynamicFavicon';
import Script from 'next/script';

type Props = AppProps & SolitoAppProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = ({ Component, pageProps: { session, isSsrMobile, ...pageProps } }: Props) => {
  const [theme, setTheme] = useRootTheme();
  return (
    <>
      <Head>
        <title>Vellapps LLC</title>
        <link rel="icon" href="/favicon.ico" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      </Head>
      <Script src="/theme.js" strategy="beforeInteractive" />
      {pageProps.seo && <NextSeo {...pageProps.seo} />}
      <DynamicFavicon theme={theme} />
      <OrganizationJsonLd
        type="LLC"
        id="https://www.vellapps.com"
        logo="https://www.vellapps.com/logo.png"
        legalName="Vellapps LLC"
        name="Vellapps"
        contactPoint={[
          {
            telephone: '+1-302-993-6460',
            contactType: 'customer service',
            areaServed: 'US',
            availableLanguage: ['English'],
          },
        ]}
        url="https://www.vellapps.com"
      />
      <NextThemeProvider onChangeTheme={setTheme}>
        <Wrapper isSsrMobile={isSsrMobile} defaultTheme={theme}>
          <Component {...pageProps} />
        </Wrapper>
      </NextThemeProvider>
    </>
  );
};

export default App;
