import * as React from 'react';
import { StatusBar } from 'react-native';
import { TamaguiProvider } from 'tamagui';
import type { TamaguiProviderProps } from '@vici/vellapps-ds';
import config from '../tamagui.config';

const Wrapper: React.FC<React.PropsWithChildren<Omit<TamaguiProviderProps, 'config'>>> = ({
  children,
  ...rest
}) => {
  return (
    <TamaguiProvider config={config} disableInjectCSS defaultTheme="dark" {...rest}>
      <StatusBar barStyle="default" />
      {children}
    </TamaguiProvider>
  );
};

export default Wrapper;
