import React from 'react';

const Favicon: React.FC<{ theme: string }> = ({ theme }) => {
  const href = React.useMemo(
    () => (theme === 'dark' ? '/favicon-light.ico' : '/favicon-dark.ico'),
    [theme],
  );

  React.useEffect(() => {
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document?.getElementsByTagName('head')?.[0]?.appendChild(link);
    }
    link.href = href;
  }, [href]);

  return null;
};

export default Favicon;
